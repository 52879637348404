import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Namespaces } from 'i18n';
import Heart from 'components/svg-icons/Heart';
import cx from 'classnames';
import { currencies } from 'constants/currencies';
import { getFullTimeDifference } from 'utils/getFullTimeDifference';
import { getTimeDifference } from 'utils/getTimeDifference';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/uk';
import 'moment/locale/ru';
import i18n from 'i18n';
import { APP_DEFAULT_LANGUAGE } from 'constants/settings';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const CoursePaymentDetails = props => {
  const {
    classes,
    course: {
      title,
      numberOfSessions,
      start,
      end,
      coursePrice,
      coursePriceDiscounted,
      coursePriceDiscountedValid,
      currency,
      coach,
    },
  } = props;

  const endUNIX = +end * 1000;
  const startUNIX = +start * 1000;

  const { t } = useTranslation(Namespaces.CoursePayment);
  const currentLanguage = i18n.language || APP_DEFAULT_LANGUAGE;
  const xsm = useMediaQuery('(max-width: 389px)');

  const currentUNIXTimestamp = Math.round(+new Date() / 1000);
  const isDiscont = +coursePriceDiscountedValid <= currentUNIXTimestamp;
  const discountValue = Math.round(
    ((coursePriceDiscounted - coursePrice) / coursePrice) * 100,
  );

  const classDuration = getTimeDifference(
    new Date(endUNIX),
    new Date(startUNIX),
  );

  const timeToStart = getFullTimeDifference(
    new Date(startUNIX),
    new Date(new Date()),
  );

  const startMomentDate = moment(startUNIX)
    .locale(currentLanguage)
    .format('dddd, DD.MM.YYYY');
  const endMomentDate = moment(endUNIX)
    .locale(currentLanguage)
    .format('dddd, DD.MM.YYYY');

  const startMomentTime = moment(startUNIX).format('HH:mm');
  const endMomentTime = moment(endUNIX).format('HH:mm');

  const preparedClassDurationStr = React.useMemo(() => {
    if (classDuration.hours && classDuration.minutes) {
      return `${classDuration.hours} ${t('course_details.times_short.hours')} ${
        classDuration.minutes
      } ${t('course_details.times_short.minutes')}`;
    } else if (classDuration.hours && !classDuration.minutes) {
      return `${classDuration.hours} ${t('course_details.times_short.hours')}`;
    } else {
      return `${classDuration.minutes} ${t(
        'course_details.times_short.minutes',
      )}`;
    }
  }, []);

  const getPriceBoxWidth = () => {
    const price = String(coursePrice);
    const discounted = String(coursePriceDiscounted);
    if (price.length < discounted.length && isDiscont) {
      return (discounted.length + 1) * 30;
    }
    return (price.length + 1) * 30;
  };

  const priceBoxWith = getPriceBoxWidth();

  const priceBoxStyles = {
    width: `${priceBoxWith}px`,
  };

  const coursePriceClasses = cx(classes.coursePriceRow, {
    [classes.coursePriceWithDiscount]: isDiscont,
    [classes.coursePriceWithoutDiscount]: !isDiscont,
  });

  const courseTimeClasses = cx(classes.courseTile, {
    [classes.courseTileXSM]: xsm,
  });

  const courseImgClasses = cx(classes.courseImg, {
    [classes.courseImgXSM]: xsm,
  });

  const courseTimesClasses = cx(classes.courseTimes, {
    [classes.courseTimesXSM]: xsm,
  });

  return (
    <div className={classes.courseDetailsBlock}>
      <div className={courseTimeClasses}>
        <div className={classes.courseTileDetails}>
          <div>
            <div className={classes.courseTitle}>
              {t('course_details.title', {
                courseName: title,
                coach: coach.fullName,
              })}
            </div>
            <div className={classes.courseSubinfo}>
              {t('course_details.classes', {
                amount: numberOfSessions,
              })}
              &nbsp;|&nbsp;{preparedClassDurationStr}
            </div>
          </div>
          <div className={classes.courseSubEst}>
            <Heart className={classes.svg} />
            {timeToStart.diff > 0 ? (
              <span className={classes.courseSubEstText}>
                {t(`course_details.starts_time.${timeToStart.format}.key`, {
                  count: timeToStart.diff,
                })}
              </span>
            ) : null}
          </div>
        </div>
        <div
          className={courseImgClasses}
          style={{
            background: `url(${coach.pictureSmall}) no-repeat center top / cover`,
          }}
        ></div>
      </div>
      <div className={courseTimesClasses}>
        <div className={classes.courseTimesRow}>
          <div className={classes.courseTimeLabel}>
            {t('course_details.start')}:
          </div>
          <div className={classes.courseTimeLabel}>
            {t('course_details.end')}:
          </div>
          <div className={classes.courseTimeLabel}>
            {t('course_details.time')}:
          </div>
        </div>
        <div className={classes.courseTimesRow}>
          <div className={classes.courseTimeValue}>{startMomentDate}</div>
          <div className={classes.courseTimeValue}>{endMomentDate}</div>
          <div className={classes.courseTimeValue}>
            {startMomentTime}&nbsp;-&nbsp;{endMomentTime}
          </div>
        </div>
      </div>
      <div className={classes.coursePrices}>
        <div className={classes.coursePricesWrap}>
          <div className={classes.coursePriceValues}>
            <div className={coursePriceClasses}>
              <span>{t('course_details.full_course')}</span>
              <div style={priceBoxStyles} className={classes.priceValue}>
                {coursePrice}
                {currencies[currency]}
              </div>
            </div>
            {isDiscont && (
              <>
                <div className={classes.coursePriceRow}>
                  <span>{t('course_details.discount')}</span>
                  <div
                    style={priceBoxStyles}
                    className={classes.courseBoldValue}
                  >
                    {discountValue &&
                    discountValue > 0 &&
                    discountValue !== Infinity ? (
                      <>{discountValue}%</>
                    ) : (
                      <> &mdash;</>
                    )}
                  </div>
                </div>
                <div className={classes.coursePriceRow}>
                  <span>{t('course_details.pay_with_discount')}</span>
                  <div
                    style={priceBoxStyles}
                    className={cx(classes.priceValue, classes.courseBoldValue)}
                  >
                    {coursePriceDiscounted}
                    {currencies[currency]}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CoursePaymentDetails.propTypes = {
  classes: PropTypes.object,
  course: PropTypes.shape({
    title: PropTypes.string,
    numberOfSessions: PropTypes.number,
    start: PropTypes.string,
    end: PropTypes.string,
    coursePrice: PropTypes.number,
    coursePriceDiscounted: PropTypes.number,
    coursePriceDiscountedValid: PropTypes.string,
    currency: PropTypes.string,
    coach: PropTypes.shape({
      fullName: PropTypes.string,
      pictureSmall: PropTypes.string,
    }),
  }),
};

export default CoursePaymentDetails;
