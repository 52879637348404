import React from 'react';
import { Helmet } from 'react-helmet';
import { Namespaces } from 'i18n';
import { useTranslation } from 'react-i18next';

const LXMetaTags = () => {
  const { t } = useTranslation(Namespaces.CoursePayment);

  return (
    <Helmet>
      <title>{t('meta.title')}</title>
      <meta name="description" content={t('meta.description')} />
    </Helmet>
  );
};

export default LXMetaTags;
