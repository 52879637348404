const changePasswordStyles = {
  loader: {
    paddingTop: '50px',
  },
  successWrap: {
    paddingTop: '40px',
  },
  logo: {
    width: '150px',
    margin: '0 auto 60px auto',

    '&:svg': {
      width: '100%',
      height: 'auto',
    },
  },
  formRow: {
    marginTop: '15px',

    '&:first-child': {
      marginTop: '0',
    },
  },
  buttonBox: {
    padding: '15px 0 10px 0',
  },
  cardHolder: {
    margin: '50px 0 35px 0',
  },
  pt0: {
    paddingTop: '0 !important',
  },
  courseDetailsBlock: {
    marginBottom: '25px',
  },
  courseTile: {
    marginBottom: '10px',
    padding: '17px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, .07)',
    borderRadius: '4px',
  },
  courseTileXSM: {
    flexDirection: 'column-reverse',
  },
  courseTileDetails: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  courseTitle: {
    fontSize: '22px',
    lineHeight: '28px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  courseImg: {
    marginLeft: '15px',
    borderRadius: '8px',
    maxHeight: '140px',
    maxWidth: '140px',
    minHeight: '140px',
    minWidth: '140px',
  },
  courseImgXSM: {
    maxWidth: '100%',
    minWidth: '100%',
    marginLeft: '0',
    marginBottom: '10px',
    minHeight: '200px',
  },
  courseSubinfo: {
    paddingTop: '15px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, .54)',
  },
  courseSubEst: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  svg: {
    fill: '#D94079',
    maxHeight: '20px',
    minHeight: '20px',
    maxWidth: '20px',
    minWidth: '20px',
  },
  courseSubEstText: {
    paddingLeft: '10px',
    fontWeight: 400,
    color: '#D94079',
    fontSize: '15px',
  },
  courseTimes: {
    paddingTop: '15px',
    textAlign: 'left',
    paddingLeft: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    display: 'flex',
  },
  courseTimesXSM: {
    paddingLeft: '0',
    fontSize: '15px',
  },
  courseTimeLabel: {
    fontWeight: 600,
    marginBottom: '7px',
  },
  courseTimeValue: {
    marginBottom: '7px',
  },
  courseTimesRow: {
    textTransform: 'capitalize',
    '&:first-child': {
      marginRight: '10px',
    },
  },
  coursePrices: {
    padding: '20px 0 10px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  coursePricesWrap: {
    display: 'flex',
  },
  coursePriceValues: {
    textAlign: 'right',
  },
  priceValue: {
    fontSize: '50px',
    lineHeight: '43px',
    textAlign: 'right',
  },
  coursePriceRow: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginTop: '15px',
    '&:first-child': {
      marginTop: '0',
    },
  },
  coursePriceWithDiscount: {
    color: 'rgba(0, 0, 0, .54)',
  },
  coursePriceWithoutDiscount: {
    '& div': {
      fontWeight: 500,
    },
  },
  courseBoldValue: {
    fontWeight: 500,
  },
};

export default changePasswordStyles;
