const notFoundStyles = {
  root: {
    padding: '100px 0',
    textAlign: 'center',
  },
  code: {
    fontSize: '100px',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '20px',
  },
};

export default notFoundStyles;
