import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const RedesignedButton = withStyles(() => ({
  root: {
    width: '194px',
    height: '40px',
    color: '#fff',
    background: '#D94079',
    borderRadius: '6px',

    '&:hover': {
      background: '#D94079',
    },
  },
}))(Button);
