const responsiveBoxStyles = {
  root: {
    position: 'relative',
    width: '450px',
    minHeight: '509px',
    margin: '0 auto',
    padding: '23px 30px 32px 30px',
    top: '5%',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    borderRadius: '6px',
  },
  rootSm: {
    width: '100%',
    padding: '0 15px 50px 15px',
  },
};

export default responsiveBoxStyles;
