const actionSuccessStyles = {
  icon: {
    width: '70px',
    height: 'auto',
    color: '#D94079',
    marginTop: '25px',
  },
};

export default actionSuccessStyles;
