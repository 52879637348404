import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import notFoundStyles from 'styles/views/NotFoundStyles';
import { Namespaces } from 'i18n';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(notFoundStyles);

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation(Namespaces.NotFound);

  return (
    <div className={classes.root}>
      <div className={classes.code}>404</div>
      <div className={classes.text}>{t('not_found_text')}</div>
    </div>
  );
};

export default NotFound;
