import React from 'react';
import PropTypes from 'prop-types';
import LXButton from 'elements/LXButton/LXButton';
import classNames from 'classnames';
import LXInputLabel from 'elements/LXInputLabel/LXInputLabel';
import FormControl from '@material-ui/core/FormControl';
import LXOutlinedInput from 'elements/LXOutlinedInput/LXOutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { formValidationSchema } from '../CoursePayment.consts';
import { formInitialValues } from '../CoursePayment.consts';
import { Namespaces } from 'i18n';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import LinearProgress from '@material-ui/core/LinearProgress';

const CoursePaymentForm = props => {
  const { classes, createUserMangopayCard, disabled } = props;
  const { t } = useTranslation(Namespaces.CoursePayment);

  const onFormSubmit = values => {
    console.log(values);
    createUserMangopayCard(values);
  };

  const formik = useFormik({
    validationSchema: formValidationSchema,
    enableReinitialize: true,
    initialValues: formInitialValues,
    onSubmit: onFormSubmit,
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const hasError = name => {
    return !!(touched[name] && errors[name]);
  };

  const handleInputFocus = e => {
    setFieldValue('focus', e.target.name);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl
        fullWidth
        className={classes.formRow}
        variant="outlined"
        error={hasError('number')}
      >
        <LXInputLabel htmlFor="number">{t('form.number')}</LXInputLabel>
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar=""
          value={values.number}
          onChange={handleChange}
          onFocus={handleInputFocus}
          disabled={disabled}
          onBlur={handleBlur}
        >
          {inputProps => (
            <LXOutlinedInput
              {...inputProps}
              id="number"
              disabled={disabled}
              name="number"
              label={t('form.number')}
              error={hasError('number')}
            />
          )}
        </InputMask>
        {hasError('number') && (
          <FormHelperText>
            {t(`form.errors.${errors.number.key}`, errors.number.values)}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl
        fullWidth
        className={classes.formRow}
        variant="outlined"
        error={hasError('name')}
      >
        <LXInputLabel htmlFor="name">{t('form.name')}</LXInputLabel>
        <LXOutlinedInput
          id="name"
          name="name"
          error={hasError('name')}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleInputFocus}
          label={t('form.name')}
          disabled={disabled}
        />
        {hasError('name') && (
          <FormHelperText>
            {t(`form.errors.${errors.name.key}`, errors.name.values)}
          </FormHelperText>
        )}
      </FormControl>
      <Grid container className={classes.formRow} spacing={2}>
        <Grid item xs={6} className={classes.pt0}>
          <FormControl fullWidth variant="outlined" error={hasError('expiry')}>
            <LXInputLabel htmlFor="expiry">{t('form.expiry')}</LXInputLabel>
            <InputMask
              mask="99/99"
              maskChar=""
              value={values.expiry}
              onChange={handleChange}
              onFocus={handleInputFocus}
              disabled={disabled}
              onBlur={handleBlur}
            >
              {inputProps => (
                <LXOutlinedInput
                  {...inputProps}
                  id="expiry"
                  disabled={disabled}
                  name="expiry"
                  label={t('form.expiry')}
                  error={hasError('expiry')}
                />
              )}
            </InputMask>
            {hasError('expiry') && (
              <FormHelperText>
                {t(`form.errors.${errors.expiry.key}`, errors.expiry.values)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} className={classes.pt0}>
          <FormControl fullWidth variant="outlined" error={hasError('cvc')}>
            <LXInputLabel htmlFor="cvc">{t('form.cvc')}</LXInputLabel>
            <InputMask
              mask="999"
              maskChar=""
              value={values.cvc}
              onChange={handleChange}
              onFocus={handleInputFocus}
              disabled={disabled}
              onBlur={handleBlur}
            >
              {inputProps => (
                <LXOutlinedInput
                  {...inputProps}
                  id="cvc"
                  disabled={disabled}
                  name="cvc"
                  label={t('form.cvc')}
                  error={hasError('cvc')}
                />
              )}
            </InputMask>
            {hasError('cvc') && (
              <FormHelperText>
                {t(`form.errors.${errors.cvc.key}`, errors.cvc.values)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <div className={classNames(classes.formRow, classes.buttonBox)}>
        <LXButton
          disabled={disabled}
          variant="contained"
          onClick={handleSubmit}
        >
          {t('form.submit')}
        </LXButton>
      </div>
      {disabled && (
        <div className={classes.formRow}>
          <LinearProgress />
        </div>
      )}
    </form>
  );
};

CoursePaymentForm.propTypes = {
  createUserMangopayCard: PropTypes.func,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
};

export default CoursePaymentForm;
