import { ApolloClient, InMemoryCache } from '@apollo/client';
import { API_KEY } from 'constants/settings';
import { getApiEndpoint } from 'utils/getApiEndpoint';

export const ApiClient = new ApolloClient({
  uri: getApiEndpoint('/graphql'),
  cache: new InMemoryCache(),
  headers: {
    'API-KEY': API_KEY,
  },
});
