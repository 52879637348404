import { useMemo } from 'react';
import queryString from 'querystring';
import { useLocation } from 'react-router-dom';

export const useLocationSearch = () => {
  const location = useLocation();
  const params = useMemo(() => queryString.decode(location.search.substr(1)), [
    location.search,
  ]);
  return [params];
};
