import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/components/headingTextStyles';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(styles);

const HeadingText = props => {
  const classes = useStyles();
  const {
    align,
    primary,
    secondary,
    rootClasses,
    primaryClasses,
    secondaryClasses,
  } = props;

  return (
    <div
      className={classNames(classes.root, rootClasses)}
      style={{
        textAlign: align,
      }}
    >
      <h2 className={classNames(classes.primary, primaryClasses)}>{primary}</h2>
      <span className={classNames(classes.secondary, secondaryClasses)}>
        {secondary}
      </span>
    </div>
  );
};

HeadingText.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string,
  rootClasses: PropTypes.string,
  primaryClasses: PropTypes.string,
  secondaryClasses: PropTypes.string,
};

export default HeadingText;
