import { InputLabel, withStyles } from '@material-ui/core';

const styles = {
  outlined: {
    '&.Mui-focused:not(.Mui-error)': {
      color: '#D94079',
    },
  },
};

export const RedesignedInputLabel = withStyles(styles)(InputLabel);
