import React from 'react';
import coursePaymentStyles from 'styles/views/CoursePaymentStyles';
import LiilixLogo from 'components/svg-icons/LiilixLogo';
import { Namespaces } from 'i18n';
import ResponsiveBox from 'components/ResponsiveBox/ResponsiveBox';
import Notification from 'components/Notification/Notification';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';
import ActionSuccess from 'components/ActionSuccess/ActionSuccess';
import {
  PROFILE_DETAILS,
  PAY_IN,
  UPDATE_PROFILE,
  GET_COURSE,
} from 'api-client/queries';
import CoursePaymentForm from './components/CoursePaymentForm';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { withApolloClient } from 'components/hocs/withApolloClient';
import { compose } from 'recompose';
import {
  MANGOPAY_CLIENT_ID,
  MANGOPAY_URL,
  MANGOPAY_API_KEY,
} from 'constants/settings';
import CoursePaymentDetails from './components/CoursePaymentDetails';

const mangoPay = require('mangopay-cardregistration-js-kit');

mangoPay.cardRegistration.baseURL = MANGOPAY_URL;
mangoPay.cardRegistration.clientId = MANGOPAY_CLIENT_ID;

class CoursePayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentProfileId: props.studentProfileId,
      courseId: props.courseId,
      isError: false,
      isLoading: true,
      isSubmitting: false,
      isSuccess: false,
      hasCardId: true,
      profile: null,
      course: null,
    };

    this.handleCloseError = this.handleCloseError.bind(this);
  }

  throwError = (e = 'Unexpected Error') => {
    this.setState({
      isError: true,
      isLoading: false,
      isSubmitting: false,
      isSuccess: false,
    });
    console.error(e);
  };

  paymentCompleted = () =>
    this.setState({
      isError: false,
      isLoading: false,
      isSubmitting: false,
      isSuccess: true,
    });

  paymentRedirect = url => (window.location.href = url);

  payInMutation = () => {
    return this.props.client.mutate({
      mutation: PAY_IN,
      variables: {
        input: {
          courseid: this.state.courseId,
          studentid: this.state.studentProfileId,
        },
      },
    });
  };

  payIn = () => {
    this.payInMutation()
      .then(({ data: { payin } }) => {
        if (payin && payin.redirecturl) {
          this.paymentRedirect(payin.redirecturl);
        } else if (payin && payin.status === 'completed') {
          this.paymentCompleted();
        } else {
          this.throwError();
        }
      })
      .catch(e => {
        this.throwError(e);
      });
  };

  fetchMangopay = async (method, body, url, contentType) => {
    return fetch(url, {
      method,
      headers: {
        Authorization: `Basic ${Buffer.from(
          `${MANGOPAY_CLIENT_ID}:${MANGOPAY_API_KEY}`,
        ).toString('base64')}`,
        'Content-Type': contentType,
      },
      body,
    }).then(res =>
      contentType === 'application/json' ? res.json() : res.text(),
    );
  };

  registerCardObject = () => {
    if (this.state.course.currency) {
      const url = `${MANGOPAY_URL}/v2.01/${MANGOPAY_CLIENT_ID}/cardregistrations`;
      const body = JSON.stringify({
        UserId: this.state.profile?.user?.mangopayUserId,
        Currency: this.state.course.currency,
      });
      return this.fetchMangopay('POST', body, url, 'application/json');
    }
  };

  addCardId = mangopayCardId => {
    return this.props.client.mutate({
      mutation: UPDATE_PROFILE,
      variables: {
        input: {
          id: this.state.studentProfileId,
          mangopayCardId,
        },
      },
    });
  };

  createUserMangopayCard = async inputData => {
    this.setState({ isSubmitting: true });
    const { cvc, expiry, name, number } = inputData;
    try {
      const cardObject = await this.registerCardObject();

      mangoPay.cardRegistration.init({
        cardRegistrationURL: cardObject.CardRegistrationURL,
        preregistrationData: cardObject.PreregistrationData,
        accessKey: cardObject.AccessKey,
        Id: cardObject.Id,
      });

      const cardInfo = await new Promise((resolve, reject) =>
        mangoPay.cardRegistration.registerCard(
          {
            cardCvx: cvc,
            cardExpirationDate: expiry.replace(/\//g, ''),
            name: name,
            cardNumber: number.replace(/\s/g, ''),
            cardType: cardObject.CardType,
          },
          resolve,
          reject,
        ),
      );

      const { data } = await this.addCardId(cardInfo.CardId);

      if (data.profile.mangopayCardId) {
        this.payIn();
      } else {
        this.throwError();
      }
    } catch (e) {
      this.throwError(e);
    }
  };

  componentDidMount = async () => {
    try {
      const {
        data: { profile },
      } = await this.props.client.query({
        query: PROFILE_DETAILS,
        variables: {
          id: this.state.studentProfileId,
        },
      });
      this.setState({ profile });
      if (profile?.mangopayCardId) {
        this.payIn();
      } else {
        const {
          data: { course },
        } = await this.props.client.query({
          query: GET_COURSE,
          variables: {
            id: this.state.courseId,
          },
        });
        this.setState({
          hasCardId: false,
          isLoading: false,
          course: course,
        });
      }
    } catch (e) {
      this.throwError(e);
    }
  };

  handleCloseError() {
    this.setState({
      isError: false,
    });
  }

  render() {
    const {
      isLoading,
      isSuccess,
      isError,
      hasCardId,
      isSubmitting,
      course,
    } = this.state;
    const { t, classes } = this.props;

    return (
      <ResponsiveBox>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress color="primary" />
          </div>
        ) : isSuccess ? (
          <div className={classes.successWrap}>
            <div className={classes.logo}>
              <LiilixLogo />
            </div>
            <ActionSuccess
              primary={t('form.success.heading.primary')}
              secondary={t('form.success.heading.secondary')}
            />
          </div>
        ) : !hasCardId && course ? (
          <>
            <CoursePaymentDetails
              course={this.state.course}
              classes={classes}
            />
            <CoursePaymentForm
              classes={classes}
              disabled={isSubmitting}
              createUserMangopayCard={this.createUserMangopayCard}
            />
          </>
        ) : null}
        <Notification
          open={isError}
          autoHideDuration={3000}
          onClose={this.handleCloseError}
          type="error"
          text={t('form.errors.query_error')}
          position={{ vertical: 'top', horizontal: 'right' }}
        />
      </ResponsiveBox>
    );
  }
}

CoursePayment.propTypes = {
  studentProfileId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  client: PropTypes.object,
  classes: PropTypes.object,
  t: PropTypes.func,
};

export default compose(
  withApolloClient,
  withStyles(coursePaymentStyles),
  withTranslation(Namespaces.CoursePayment),
)(CoursePayment);
