import moment from 'moment';

export const getFullTimeDifference = (time1, time2) => {
  const moment1 = moment(time1);
  const moment2 = moment(time2);
  const diff = moment.duration(moment1.diff(moment2), 'millisecond');
  const minutes = Math.abs(Math.round(diff.asMinutes()));
  const hours = Math.abs(Math.round(diff.asHours()));
  const days = Math.abs(Math.round(diff.asDays()));

  if (minutes <= 60) {
    return {
      diff: minutes,
      format: 'minutes',
    };
  } else if (days >= 1) {
    return {
      diff: days,
      format: 'days',
    };
  } else {
    return {
      diff: hours,
      format: 'hours',
    };
  }
};
