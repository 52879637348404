import React from 'react';

const liilixLogo = () => {
  return (
    <svg viewBox="0 0 143 32">
      <path
        fill="#000"
        style={{ fill: 'var(--color1, #000)' }}
        d="M8.439 5.729c0-1.523-1.239-2.761-2.761-2.761h-2.916c-1.523 0.026-2.761-0.645-2.761-1.471 0-0.8 1.239-1.471 2.761-1.471h6.89c1.497 0 2.735 1.239 2.735 2.761v26.245c0 1.523-0.877 2.761-1.987 2.761s-1.987-1.239-1.987-2.761v-23.303h0.026z"
      ></path>
      <path
        fill="#000"
        style={{ fill: 'var(--color1, #000)' }}
        d="M82.813 5.729c0-1.523-1.239-2.761-2.761-2.761h-2.942c-1.523 0-2.761-0.671-2.761-1.471 0-0.826 1.239-1.471 2.761-1.471h6.89c1.523 0 2.761 1.239 2.761 2.761v26.245c0 1.523-0.877 2.761-1.987 2.761-1.084 0-1.987-1.239-1.987-2.761v-23.303h0.026z"
      ></path>
      <path
        fill="#000"
        style={{ fill: 'var(--color1, #000)' }}
        d="M106.606 2.503c0-1.368 1.11-2.477 2.477-2.477s2.477 1.11 2.477 2.477c0 1.368-1.11 2.477-2.477 2.477-1.368-0.026-2.477-1.135-2.477-2.477zM107.097 15.665c0-1.523-1.239-2.761-2.761-2.761h-2.452c-1.523 0-2.761-0.671-2.761-1.471 0-0.826 1.239-1.471 2.761-1.471h6.4c1.523 0 2.761 1.239 2.761 2.761v16.31c0 1.523-0.877 2.761-1.987 2.761-1.084 0-1.987-1.239-1.987-2.761l0.026-13.368z"
      ></path>
      <path
        fill="#000"
        style={{ fill: 'var(--color1, #000)' }}
        d="M128.826 22.968c0.903-1.213 0.877-3.2-0.026-4.413l-4.774-6.4c-0.903-1.213-0.516-2.194 0.852-2.194s3.226 0.981 4.129 2.219l2.735 3.665c0.903 1.213 2.348 1.187 3.2-0.052l2.4-3.535c0.852-1.239 2.452-2.271 3.535-2.271s1.265 1.032 0.413 2.271l-4.284 6.245c-0.852 1.239-0.826 3.252 0.077 4.465l4.955 6.632c0.903 1.213 0.542 2.194-0.826 2.194s-3.226-0.981-4.129-2.194l-2.942-3.923c-0.903-1.213-2.4-1.213-3.277 0.026l-2.865 3.897c-0.903 1.213-2.529 2.219-3.639 2.219s-1.265-1.006-0.361-2.219l4.826-6.632z"
      ></path>
      <path
        fill="#000"
        style={{ fill: 'var(--color1, #000)' }}
        d="M54.632 4.955c-1.368 0-2.477-1.11-2.477-2.477s1.11-2.477 2.477-2.477c1.368 0 2.477 1.11 2.477 2.477s-1.11 2.477-2.477 2.477zM56.619 29.032c0 1.523-0.877 2.761-1.987 2.761-1.084 0-1.987-1.239-1.987-2.761v-16.335c0-1.523 1.239-2.761 2.761-2.761h6.4c1.523 0 2.761 0.671 2.761 1.471s-1.239 1.471-2.761 1.471h-2.452c-1.523 0-2.761 1.239-2.761 2.761l0.026 13.394z"
      ></path>
      <path
        fill="#d94179"
        style={{ fill: 'var(--color2, #d94179)' }}
        d="M32.413 4.955c-1.368 0-2.477-1.11-2.477-2.477s1.11-2.477 2.477-2.477c1.368 0 2.477 1.11 2.477 2.477s-1.11 2.477-2.477 2.477zM34.4 29.032c0 1.523-0.877 2.761-1.987 2.761s-1.987-1.239-1.987-2.761v-16.335c0-1.523 1.239-2.761 2.761-2.761h6.4c1.523 0 2.761 0.671 2.761 1.471s-1.239 1.471-2.761 1.471h-2.426c-1.523 0-2.761 1.239-2.761 2.761v13.394z"
      ></path>
    </svg>
  );
};

export default liilixLogo;
