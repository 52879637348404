import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import actionSuccessStyles from 'styles/components/actionSuccessStyles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import HeadingText from 'components/HeadingText/HeadingText';
import PropTypes from 'prop-types';

const useStyles = makeStyles(actionSuccessStyles);

const ActionSuccess = props => {
  const classes = useStyles();
  const { primary, secondary } = props;

  return (
    <>
      <HeadingText primary={primary} secondary={secondary} />
      <div>
        <ThumbUpIcon className={classes.icon} />
      </div>
    </>
  );
};

export default ActionSuccess;

ActionSuccess.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};
