export const APP_DEFAULT_LANGUAGE = 'en';

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_KEY,
  REACT_APP_MANGOPAY_URL,
  REACT_APP_MANGOPAY_CLIENT_ID,
  REACT_APP_MANGOPAY_API_KEY,
} = process.env;

export const API_ENDPOINT =
  REACT_APP_API_ENDPOINT || `${window.location.origin}/api`;
export const API_KEY = REACT_APP_API_KEY;

export const MANGOPAY_URL = REACT_APP_MANGOPAY_URL;
export const MANGOPAY_CLIENT_ID = REACT_APP_MANGOPAY_CLIENT_ID;
export const MANGOPAY_API_KEY = REACT_APP_MANGOPAY_API_KEY;
