import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import responsiveBoxStyles from 'styles/components/responsiveBoxStyles';

const useStyles = makeStyles(responsiveBoxStyles);

const ResponsiveBox = props => {
  const sm = useMediaQuery('(max-width: 500px)');
  const classes = useStyles();

  return (
    <Box
      boxShadow={!sm ? 4 : 0}
      className={classNames(classes.root, {
        [classes.rootSm]: sm,
      })}
    >
      {props.children}
    </Box>
  );
};

ResponsiveBox.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveBox;
