import * as Yup from 'yup';
import { setLocale } from 'yup';
import valid from 'card-validator';

setLocale({
  mixed: {
    default: () => ({ key: 'default', values: {} }),
    required: () => ({ key: 'empty', values: {} }),
  },
});

export const formInitialValues = {
  cvc: '',
  expiry: '',
  focus: '',
  name: '',
  number: '',
};

export const formValidationSchema = Yup.object({
  cvc: Yup.string()
    .test(
      'cvc',
      { key: 'cvc_error', value: {} },
      value => valid.cvv(value).isValid,
    )
    .required(),
  expiry: Yup.string()
    .test(
      'expiry',
      { key: 'expiry_error', value: {} },
      value => valid.expirationDate(value).isValid,
    )
    .required(),
  focus: Yup.string()
    .trim()
    .required(),
  name: Yup.string()
    .trim()
    .required(),
  number: Yup.string()
    .test(
      'card_number',
      { key: 'card_number_error', value: {} },
      value => valid.number(value).isValid,
    )
    .required(),
});
