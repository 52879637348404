import React from 'react';
import { RedesignedButton } from 'styles/elements/LXButtonStyles';

const LXButton = React.forwardRef((props, ref) => (
  <RedesignedButton {...props} ref={ref} />
));

LXButton.displayName = 'LXButton';

export default LXButton;
