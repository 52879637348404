import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import BasicRoutes from 'constants/basicRoutes';
import NotFound from 'views/NotFound/NotFound';
import ParamsRouteHandler from 'components/ParamsRouteHandler/ParamsRouteHandler';
import QueriesRouteHandler from 'components/QueriesRouteHandler/QueriesRouteHandler';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={BasicRoutes.PAYMENT_PARAMS}
          component={ParamsRouteHandler}
        />
        <Route
          path={BasicRoutes.PAYMENT_QUERIES}
          component={QueriesRouteHandler}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
