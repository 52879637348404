import React from 'react';
import CoursePayment from 'views/CoursePayment/CoursePayment';
import NotFound from 'views/NotFound/NotFound';
import { useLocationSearch } from 'components/hooks/useLocationSearch';

const QueriesRouteHandler = () => {
  const [search] = useLocationSearch();
  const { studentProfileId, courseId } = search;

  return (
    <>
      {studentProfileId && courseId ? (
        <CoursePayment
          studentProfileId={studentProfileId}
          courseId={courseId}
        />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default QueriesRouteHandler;
