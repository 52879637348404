import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const Notification = props => {
  const {
    open,
    autoHideDuration = 5000,
    onClose,
    type,
    text,
    position = {
      vertical: 'bottom',
      horizontal: 'center',
    },
  } = props;

  const { vertical, horizontal } = position;

  return (
    <Snackbar
      anchorOrigin={position}
      key={vertical + horizontal}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      style={{ textAlign: 'left' }}
    >
      <Alert onClose={onClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
};

Notification.propTypes = {
  open: PropTypes.bool.isRequired,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
  text: PropTypes.string.isRequired,
  position: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'bottom']).isRequired,
    horizontal: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  }),
};

export default Notification;
