import moment from 'moment';

export const getTimeDifference = (end, start) => {
  const startMockDate = new Date(Date.UTC(2020, 1, 30));
  const endMockDate = new Date(Date.UTC(2020, 1, 30));

  startMockDate.setHours(start.getHours());
  startMockDate.setMinutes(start.getMinutes());
  endMockDate.setHours(end.getHours());
  endMockDate.setMinutes(end.getMinutes());

  const diff = moment.duration(
    moment(endMockDate).diff(moment(startMockDate)),
    'millisecond',
  );

  const diffHours =
    Math.abs(diff.asMinutes()) === 60
      ? 0
      : Math.floor(Math.abs(diff.asHours()));
  const diffMinutes =
    Math.abs(diff.asMinutes()) > 60
      ? Math.abs(diff.asMinutes()) - diffHours * 60
      : Math.abs(diff.asMinutes());

  return {
    hours: diffHours,
    minutes: diffMinutes,
  };
};
