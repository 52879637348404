import React from 'react';
import { RedesignedOutlineInput } from 'styles/elements/LXOutlinedInputStyles';

const LXOutlinedInput = React.forwardRef((props, ref) => (
  <RedesignedOutlineInput {...props} ref={ref} />
));

LXOutlinedInput.displayName = 'LXOutlinedInput';

export default LXOutlinedInput;
