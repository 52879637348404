import React from 'react';
import CoursePayment from 'views/CoursePayment/CoursePayment';
import NotFound from 'views/NotFound/NotFound';
import { useParams } from 'react-router-dom';

const ParamsRouteHandler = () => {
  const { studentProfileId, courseId } = useParams();

  return (
    <>
      {studentProfileId && courseId ? (
        <CoursePayment
          studentProfileId={studentProfileId}
          courseId={courseId}
        />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ParamsRouteHandler;
