import { OutlinedInput, withStyles } from '@material-ui/core';

export const RedesignedOutlineInput = withStyles(() => ({
  root: {
    '&.MuiInputBase-fullWidth': {
      width: '100%',
    },
    '&$disabled': {
      backgroundColor: '#f9e6ed',
      color: '#D7CAC4',
      cursor: 'not-allowed',
    },
    '& .MuiFormLabel-root.Mui-focused:not(.Mui-error)': {
      color: '#D94079',
    },

    '&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#D94079',
    },
  },
}))(OutlinedInput);
