import { createMuiTheme } from '@material-ui/core';

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#D94079',
      contrastText: '#FFFFFF',
    },
  },
});
