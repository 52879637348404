import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApiClient } from 'api-client';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { appTheme } from 'theme';
import Routes from 'Router';
import LXMetaTags from 'components/LXMetaTags/LXMetaTags';

function App() {
  return (
    <>
      <LXMetaTags />
      <ApolloProvider client={ApiClient}>
        <MuiThemeProvider theme={appTheme}>
          <Routes />
        </MuiThemeProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
