const headingTextStyles = {
  root: {
    textAlign: 'center',
  },
  primary: {
    fontSize: '19px',
    margin: '0 0 8px 0',
  },
  secondary: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

export default headingTextStyles;
