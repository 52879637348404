import { gql } from '@apollo/client';

export const PROFILE_DETAILS = gql`
  query profile($id: String) {
    profile(id: $id) {
      mangopayCardId
      user {
        mangopayUserId
      }
    }
  }
`;

export const PAY_IN = gql`
  mutation payin($input: payinInput) {
    payin(input: $input) {
      status
      redirecturl
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation profile($input: iProfile) {
    profile(input: $input) {
      mangopayCardId
    }
  }
`;

export const GET_COURSE = gql`
  query($id: String!) {
    course(id: $id) {
      title
      numberOfSessions
      start
      end
      coursePrice
      coursePriceDiscounted
      coursePriceDiscountedValid
      currency
      coach {
        fullName
        pictureSmall
      }
    }
  }
`;
