import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { APP_DEFAULT_LANGUAGE } from 'constants/settings';
import HttpApi from 'i18next-http-backend';

export const Namespaces = {
  CoursePayment: 'coursePayment',
  NotFound: 'notFound',
};

export const Languages = {
  En: 'en',
  De: 'de',
  Ru: 'ru',
  Uk: 'uk',
};

const createI18nInstance = ({ language }) => {
  const instance = i18next.createInstance();
  instance
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      lng: language,
      fallbackLng: APP_DEFAULT_LANGUAGE,
      load: 'languageOnly',
      supportedLngs: Object.values(Languages),
      react: {
        useSuspense: false,
        bindI18n: 'languageChanged',
      },
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: `${window.location.origin}/locales/{{ns}}/{{lng}}.json`,
      },
    });

  return instance;
};

const i18n = createI18nInstance({});

export default i18n;
